export const CURRENT_EDITION = '2025';
export const USER_NOTES = 'films/' + CURRENT_EDITION +'/NOTES/'
export const SIGNIN_FILMS_CODE = []; // 'FILMAX' 
export const SIGNIN_FILMS = true; // INSCRIPCIONS OBERTES
export const SIGNIN_FILMS_USER = false; // INSCRIPCIONS OBERTES PER USUARIS
export const SIGNUP_NEW_USERS= false; // INSCRIPCIONS OBERTES PER USUARIS
export const SIGNIN_YOUNG = false; // INSCRIPCIONS OBERTES
export const RATE_FILMS = true; // VALORACIONS OBERTES
export const TOP_FILMS = 'admin'; // false, true, 'admin' or 'dev'
export const MAX_CURTS = 240;
export const MAX_CURTS_NUM = 12;
export const MAX_CURTS_FICCIO = 180; // MINUTES
export const MAX_CURTS_DOCS = 60; // MINUTES
export const MAX_CURTS_VIDEOCLIPS = 60;  // MINUTES
export const MAX_CENTRES = 120;  // MINUTES
export const MAX_CENTRES_PRIMARIA = 60;  // MINUTES
export const MAX_CENTRES_SECUNDARIA = 60; // MINUTES
export const MAX_DOCS = 6; // UNITS
export const MAX_LLARGS = 6; // UNITS 
export const SEND_EMAIL_URL = 'https://triggermail-e4wjw26kzq-uc.a.run.app';
export const CURTS = ['filmTarragona','filmGenre','filmCategory','filmName','filmYear','filmCity','filmState','filmCountry','filmLanguage','filmLangCAT','filmLangES','filmLangEN','filmLangOT','filmLength','filmPremiere','filmDirector','filmDirectorEmail','producerCompany','producerSite','filmActoring','filmSpecialEdition','filmSynopsis','filmTechnicalCast','appTechnicalCast','appShortDescription','isOnline','isOffline','eventDate','eventLinkTv3','eventLinkTv3StartDate','eventLinkTv3EndDate','eventLinkFilmin','contactName','contactRole','contactEmail','contactPhone','imageCover','imageFrame1','imageFrame2','imageFrame3','filmLink','filmPass','acceptOwn','acceptOnline'];
export const LLARGS = ['filmTarragona','filmGenre','filmName','filmYear','filmCity','filmState','filmCountry','filmLanguage','filmLangCAT','filmLangES','filmLangEN','filmLangOT','filmLength','filmPremiere','filmDirector','filmDirectorEmail','producerCompany','producerSite','filmActoring','filmSpecialEdition','filmSynopsis','filmTechnicalCast','appTechnicalCast','appShortDescription','isOnline','isOffline','eventDate','eventLinkTv3','eventLinkTv3StartDate','eventLinkTv3EndDate','eventLinkFilmin','contactName','contactRole','contactEmail','contactPhone','imageCover','imageFrame1','imageFrame2','imageFrame3','filmLink','filmPass','acceptOwn','acceptOnline'];
export const DOCS = ['filmTarragona','filmName','filmYear','filmCity','filmState','filmCountry','filmLanguage','filmLangCAT','filmLangES','filmLangEN','filmLangOT','filmLength','filmPremiere','filmDirector','filmDirectorEmail','producerCompany','producerSite','filmActoring','filmSpecialEdition','filmSynopsis','filmTechnicalCast','appTechnicalCast','appShortDescription','isOnline','isOffline','eventDate','eventLinkTv3','eventLinkTv3StartDate','eventLinkTv3EndDate','eventLinkFilmin','contactName','contactRole','contactEmail','contactPhone','imageCover','imageFrame1','imageFrame2','imageFrame3','filmLink','filmPass','acceptOwn','acceptOnline'];
export const CENTRES = ['filmCategory','filmSchool','filmSchoolLevel','filmSchoolTutor','filmName','filmYear','filmCity','filmState','filmCountry','filmLanguage','filmLangCAT','filmLangES','filmLangEN','filmLangOT','filmLength','filmPremiere','filmDirector','filmDirectorEmail','filmActoring','filmSpecialEdition','filmSynopsis','filmTechnicalCast','appTechnicalCast','appShortDescription','isOnline','isOffline','eventDate','eventLinkTv3','eventLinkTv3StartDate','eventLinkTv3EndDate','eventLinkFilmin','contactName','contactRole','contactEmail','contactPhone','imageCover','imageFrame1','imageFrame2','imageFrame3','filmLink','filmPass','acceptOwn','acceptOnline'];
export const VIDEOCLIPS = ['filmTarragona','filmBand','filmName','filmYear','filmCity','filmState','filmCountry','filmLanguage','filmLangCAT','filmLangES','filmLangEN','filmLangOT','filmLength','filmPremiere','filmDirector','filmDirectorEmail','producerCompany','producerSite','filmActoring','filmSpecialEdition','filmSynopsis','filmTechnicalCast','appTechnicalCast','appShortDescription','isOnline','isOffline','eventDate','eventLinkTv3','eventLinkTv3StartDate','eventLinkTv3EndDate','eventLinkFilmin','contactName','contactRole','contactEmail','contactPhone','imageCover','imageFrame1','imageFrame2','imageFrame3','filmLink','filmPass','acceptOwn','acceptOnline'];
export const JOVES = ['filmCity','filmState','filmCountry','filmTechnicalCast','appTechnicalCast','appShortDescription','isOnline','isOffline','eventDate','eventLinkYouTube','contactName','contactEmail','contactPhone','contactNIF','contactBirth','contactAdress'];
export const GENRES = ['Acció','Animació','Aventura','Catàstrofe','Ciència Ficció','Comèdia','Documental','Drama','Fantasia','Musical','Suspens','Terror','Altres']
export const SPAIN_STATES = ['Àlaba', 'Albacete', 'Alacant', 'Almeria', 'Astúries', 'Avila', 'Badajoz', 'Barcelona', 'Burgos', 'Càceres',
    'Cadis', 'Cantàbria', 'Castelló', 'Ciudad Real', 'Còrdova', 'La Corunya', 'Conca', 'Girona', 'Granada', 'Guadalajara',
    'Guipúscoa', 'Huelva', 'Osca', 'Illes Balears', 'Jaén', 'Lleó', 'Lleida', 'Lugo', 'Madrid', 'Màlaga', 'Múrcia', 'Navarra',
    'Orense', 'Palència', 'Las Palmas', 'Pontevedra', 'la Rioja', 'Salamanca', 'Segòvia', 'Sevilla', 'Soria', 'Tarragona',
    'Santa Cruz de Tenerife', 'Terol', 'Toledo', 'València', 'Valladolid', 'Biscaia', 'Zamora', 'Saragossa' ];

export const COUNTRIES = [
    "Espanya",
    "Afganistan",
    "Albània",
    "Algèria",
    "Samoa Americana",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antàrtida",
    "Antigua i Barbuda",
    "Argentina",
    "Armènia",
    "Aruba",
    "Austràlia",
    "Àustria",
    "Azerbaidjan",
    "Bahames (les)",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Bielorússia",
    "Bèlgica",
    "Belize",
    "Benín",
    "Bermuda",
    "Bhutan",
    "Bolívia (estat plurinacional de)",
    "Bonaire, Sint Eustatius i Saba",
    "Bòsnia i Hercegovina",
    "Botswana",
    "Illa Bouvet",
    "Brasil",
    "Territori britànic de l'oceà Índic (el)",
    "Brunei Darussalam",
    "Bulgària",
    "Burkina Faso",
    "Burundi",
    "Cap Verd",
    "Cambodja",
    "Camerun",
    "Canadà",
    "Illes Caiman (les)",
    "República Centreafricana (la)",
    "Txad",
    "Xile",
    "Xina",
    "Illa de Nadal",
    "Illes Cocos (Keeling) (les)",
    "Colòmbia",
    "Comores (les)",
    "Congo (República Democràtica del)",
    "Congo (el)",
    "Illes Cook (les)",
    "Costa Rica",
    "Croàcia",
    "Cuba",
    "Curaçao",
    "Xipre",
    "Txèquia",
    "Costa d'Ivori",
    "Dinamarca",
    "Djibouti",
    "Dominica",
    "República Dominicana (la)",
    "Equador",
    "Egipte",
    "El Salvador",
    "Guinea Equatorial",
    "Eritrea",
    "Estònia",
    "Eswatini",
    "Etiòpia",
    "Illes Malvines [Malvinas]",
    "Illes Fèroe (les)",
    "Fiji",
    "Finlàndia",
    "França",
    "Guaiana Francesa",
    "Polinèsia Francesa",
    "Territoris australs francesos (els)",
    "Gabon",
    "Gàmbia (el)",
    "Geòrgia",
    "Alemanya",
    "Ghana",
    "Gibraltar",
    "Grècia",
    "Groenlàndia",
    "Granada",
    "Guadalupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haití",
    "Heard Island i McDonald Islands",
    "Santa Seu (la)",
    "Hondures",
    "Hong Kong",
    "Hongria",
    "Islàndia",
    "Índia",
    "Indonèsia",
    "Iran (República Islàmica de)",
    "Iraq",
    "Irlanda",
    "Illa de l'home",
    "Israel",
    "Itàlia",
    "Jamaica",
    "Japó",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Corea (República Popular Democràtica de)",
    "Corea (la República de)",
    "Kuwait",
    "Kirguizistan",
    "República Democràtica Popular Lao (la)",
    "Letònia",
    "Líban",
    "Lesotho",
    "Libèria",
    "Líbia",
    "Liechtenstein",
    "Lituània",
    "Luxemburg",
    "Macau",
    "Madagascar",
    "Malawi",
    "Malàisia",
    "Maldives",
    "Mali",
    "Malta",
    "Illes Marshall (les)",
    "Martinica",
    "Mauritània",
    "Maurici",
    "Mayotte",
    "Mèxic",
    "Micronèsia (Estats federats de)",
    "Moldàvia (la República de)",
    "Mònaco",
    "Mongòlia",
    "Montenegro",
    "Montserrat",
    "Marroc",
    "Moçambic",
    "Myanmar",
    "Namíbia",
    "Nauru",
    "Nepal",
    "Països Baixos (els)",
    "Nova Caledònia",
    "Nova Zelanda",
    "Nicaragua",
    "Níger (el)",
    "Nigèria",
    "Niue",
    "Illa Norfolk",
    "Illes Mariannes del Nord (les)",
    "Noruega",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestina, Estat de",
    "Panamà",
    "Papua Nova Guinea",
    "Paraguai",
    "Perú",
    "Filipines (les)",
    "Pitcairn",
    "Polònia",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "República de Macedònia del Nord",
    "Romania",
    "Federació Russa (la)",
    "Ruanda",
    "Reunió",
    "Saint Barthélemy",
    "Santa Helena, Ascensió i Tristan da Cunha",
    "Saint Kitts i Nevis",
    "Santa Llúcia",
    "Sant Martí (part francesa)",
    "Saint Pierre i Miquelon",
    "Sant Vicenç i les Granadines",
    "Samoa",
    "San Marino",
    "Sao Tomé i Príncep",
    "Aràbia Saudita",
    "Senegal",
    "Sèrbia",
    "Seychelles",
    "Sierra Leone",
    "Singapur",
    "Sint Maarten (part holandesa)",
    "Eslovàquia",
    "Eslovènia",
    "Illes Salomó",
    "Somàlia",
    "Sud-Àfrica",
    "Geòrgia del Sud i les illes Sandwich del Sud",
    "Sudan del Sud",
    "Sri Lanka",
    "Sudan (el)",
    "Surinam",
    "Svalbard i Jan Mayen",
    "Suècia",
    "Suïssa",
    "República Àrab de Síria",
    "Taiwan",
    "Tadjikistan",
    "Tanzània, República Unida de",
    "Tailàndia",
    "Timor-Leste",
    "Anar",
    "Tokelau",
    "Tonga",
    "Trinitat i Tobago",
    "Tunísia",
    "Turquia",
    "Turkmenistan",
    "Illes Turks i Caicos (les)",
    "Tuvalu",
    "Uganda",
    "Ucraïna",
    "Emirats Àrabs Units (els)",
    "Regne Unit de Gran Bretanya i Irlanda del Nord (el)",
    "Illes perifèriques menors dels Estats Units (les)",
    "Estats Units d'Amèrica (els)",
    "Uruguai",
    "Uzbekistan",
    "Vanuatu",
    "Veneçuela (República Bolivariana de)",
    "Vietnam",
    "Illes Verges (britàniques)",
    "Illes Verges (EUA)",
    "Wallis i Futuna",
    "Sàhara Occidental",
    "Iemen",
    "Zàmbia",
    "Zimbabwe",
    "Illes Åland"
];