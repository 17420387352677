import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as CONFIG from '../../constants/config';

const Navigation = ({ authUser }) =>
  authUser ? (
    <NavigationAuth authUser={authUser} />
  ) : (
    <NavigationNonAuth />
  );



const NavigationEdition = () => {

  const currentEdition = localStorage.getItem('currentEdition') !== null ? localStorage.getItem('currentEdition') : CONFIG.CURRENT_EDITION; 

  const setYear = (e) => {

    const year = Math.round(e.target.value);

    localStorage.setItem('currentEdition', year);

    location.reload();
  } 

  return (
    
    <div>
      <Form.Group controlId="filmCategory" className="mx-2 mb-0">
          <Form.Control as="select" defaultValue={currentEdition.toString()} onChange={e => setYear(e)}>
            <option value='2020'>2020</option>
            <option value='2022'>2022</option>
            <option value='2023'>2023</option>
            <option value='2024'>2024</option>
            <option value='2025'>2025</option>
            </Form.Control>
        </Form.Group>
    </div>
  )
} 
// AuthUser.roles[ROLES.ADMIN] 

const NavigationAuth = ({ authUser }) => (
  <div>
    { !!authUser && (
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="#home" className="d-none d-lg-block"><img alt="FIC-CAT" style={{ width: '70px', margin: '0 0 0 10px' }} src="/img/logo.png" /></Navbar.Brand>
        { authUser && !authUser.roles['JURAT'] && (
        <NavigationEdition />
        )}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        { (!authUser.roles['ADMIN'] && !authUser.roles['EDITOR'] && !authUser.roles['JURAT'] && CONFIG.TOP_FILMS !== true ) ? ( 
        <Nav className="me-auto">
            <Nav.Link className='px-2' href={ROUTES.LIST_LLARGS}>Llargmetratges</Nav.Link>
            <Nav.Link className='px-2' href={ROUTES.LIST_DOCS}>Documentals</Nav.Link>
            <Nav.Link className='px-2' href={ROUTES.LIST_CURTS}>Curtmetratges</Nav.Link>
            <Nav.Link className='px-2' href={ROUTES.LIST_VIDEOCLIPS}>Videoclips</Nav.Link>
            <Nav.Link className='px-2' href={ROUTES.LIST_CENTRES}>Centres</Nav.Link>
            <Nav.Link className='px-2' href={ROUTES.LIST_JOVES}>Jove 48</Nav.Link>
        </Nav>
        ) : (
          <>
          {!authUser.roles['JURAT'] ? (
          <Dropdown>
            <Dropdown.Toggle variant="light" id="dropdown-inscripcions">
              Llistat Inscripcions
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href={ROUTES.LIST_LLARGS}>Llargmetratges</Dropdown.Item>
              <Dropdown.Item href={ROUTES.LIST_DOCS}>Documentals</Dropdown.Item>
              <Dropdown.Item href={ROUTES.LIST_CURTS}>Curtmetratges</Dropdown.Item>
              <Dropdown.Item href={ROUTES.LIST_VIDEOCLIPS}>Videoclips</Dropdown.Item>
              <Dropdown.Item href={ROUTES.LIST_CENTRES}>Centres</Dropdown.Item>
              <Dropdown.Item href={ROUTES.LIST_JOVES}>Jove 48</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          ) : (
            <Nav className='me-auto'>
              { ((authUser.roles['JURAT'] && authUser.roles['JURAT'] === 'OFICIAL') || (!authUser.roles['JURAT'])) && (
              <>
                <Nav.Link className='px-2' href={ROUTES.CHOICE_LLARGS}>Llargmetratges</Nav.Link>
                <Nav.Link className='px-2' href={ROUTES.CHOICE_DOCS}>Documentals</Nav.Link>
                <Nav.Link className='px-2' href={ROUTES.CHOICE_CURTS}>Curtmetratges</Nav.Link>
                <Nav.Link className='px-2' href={ROUTES.CHOICE_VIDEOCLIPS}>Videoclips</Nav.Link>
              </>
              )}
              { ((authUser.roles['JURAT'] && authUser.roles['JURAT'] === 'CENTRES') || (!authUser.roles['JURAT'])) && (
              <Nav.Link className='px-2' href={ROUTES.CHOICE_CENTRES}>Centres</Nav.Link>
              )}
              { ((authUser.roles['JURAT'] && authUser.roles['JURAT'] === 'JOVE') || (!authUser.roles['JURAT'])) && (
              <Nav.Link className='px-2' href={ROUTES.CHOICE_JOVES}>Jove 48</Nav.Link>
              )}
          </Nav>
          )}
        </>
        )}
        { (( authUser.roles['ADMIN'] && CONFIG.TOP_FILMS === 'admin') &&
          ( !authUser.roles['JURAT'] ) ||
          ( CONFIG.TOP_FILMS === true )) && (
        <Dropdown>
          <Dropdown.Toggle variant="light" id="dropdown-inscripcions">
            Selecció official
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href={ROUTES.CHOICE_LLARGS}>Llargmetratges</Dropdown.Item>
            <Dropdown.Item href={ROUTES.CHOICE_DOCS}>Documentals</Dropdown.Item>
            <Dropdown.Item href={ROUTES.CHOICE_CURTS}>Curtmetratges</Dropdown.Item>
            <Dropdown.Item href={ROUTES.CHOICE_VIDEOCLIPS}>Videoclips</Dropdown.Item>
            <Dropdown.Item href={ROUTES.CHOICE_CENTRES}>Centres educatius</Dropdown.Item>
            <Dropdown.Item href={ROUTES.CHOICE_JOVES}>Jove 48</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
         )}
        { (( authUser.roles['ADMIN'] && CONFIG.TOP_FILMS === 'admin') ||
          ( authUser.roles['DEV'] && CONFIG.TOP_FILMS === 'dev') ||
          ( CONFIG.TOP_FILMS === true && !authUser.roles['JURAT'] )) && (
            <>
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-inscripcions">
                Top
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href={ROUTES.TOP_LLARGS}>Llargmetratges</Dropdown.Item>
                <Dropdown.Item href={ROUTES.TOP_DOCS}>Documentals</Dropdown.Item>
                <Dropdown.Item href={ROUTES.TOP_CURTS}>Curtmetratges</Dropdown.Item>
                <Dropdown.Item href={ROUTES.TOP_VIDEOCLIPS}>Videoclips</Dropdown.Item>
                <Dropdown.Item href={ROUTES.TOP_CENTRES}>Centres</Dropdown.Item>
                <Dropdown.Item href={ROUTES.TOP_JOVES}>Jove 48</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
        { (authUser.roles['ADMIN'] || authUser.roles['DEV']) && false  && (
            <>
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-inscripcions">
                Sel. Jurat
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href={ROUTES.CHOICE_JUDGE_LLARGS}>Llargmetratges</Dropdown.Item>
                <Dropdown.Item href={ROUTES.CHOICE_JUDGE_DOCS}>Documentals</Dropdown.Item>
                <Dropdown.Item href={ROUTES.CHOICE_JUDGE_CURTS}>Curtmetratges</Dropdown.Item>
                <Dropdown.Item href={ROUTES.CHOICE_JUDGE_VIDEOCLIPS}>Videoclips</Dropdown.Item>
                <Dropdown.Item href={ROUTES.CHOICE_JUDGE_CENTRES}>Centres</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
         { (authUser.roles['ADMIN'] || authUser.roles['DEV'])  && (
            <>
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-inscripcions">
                Sel. Comunicació
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href={ROUTES.CHOICE_COMM_LLARGS}>Llargmetratges</Dropdown.Item>
                <Dropdown.Item href={ROUTES.CHOICE_COMM_DOCS}>Documentals</Dropdown.Item>
                <Dropdown.Item href={ROUTES.CHOICE_COMM_CURTS}>Curtmetratges</Dropdown.Item>
                <Dropdown.Item href={ROUTES.CHOICE_COMM_VIDEOCLIPS}>Videoclips</Dropdown.Item>
                <Dropdown.Item href={ROUTES.CHOICE_COMM_CENTRES}>Centres</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
        { (authUser.roles['ADMIN'] || authUser.roles['DEV'])  && (
            <>
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-inscripcions">
                @ Selecció
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href={ROUTES.MAIL_SELECTED_LLARGS}>Llargmetratges</Dropdown.Item>
                <Dropdown.Item href={ROUTES.MAIL_SELECTED_DOCS}>Documentals</Dropdown.Item>
                <Dropdown.Item href={ROUTES.MAIL_SELECTED_CURTS}>Curtmetratges</Dropdown.Item>
                <Dropdown.Item href={ROUTES.MAIL_SELECTED_VIDEOCLIPS}>Videoclips</Dropdown.Item>
                <Dropdown.Item href={ROUTES.MAIL_SELECTED_CENTRES}>Centres</Dropdown.Item>
                <Dropdown.Item href={ROUTES.MAIL_SELECTED_JOVES}>Jove 48</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
        { (authUser.roles['ADMIN'] || authUser.roles['DEV'])  && (
            <>
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-inscripcions">
                @ Participants
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href={ROUTES.MAIL_COMPETITOR_LLARGS}>Llargmetratges</Dropdown.Item>
                <Dropdown.Item href={ROUTES.MAIL_COMPETITOR_DOCS}>Documentals</Dropdown.Item>
                <Dropdown.Item href={ROUTES.MAIL_COMPETITOR_CURTS}>Curtmetratges</Dropdown.Item>
                <Dropdown.Item href={ROUTES.MAIL_COMPETITOR_VIDEOCLIPS}>Videoclips</Dropdown.Item>
                <Dropdown.Item href={ROUTES.MAIL_COMPETITOR_CENTRES}>Centres</Dropdown.Item>
                <Dropdown.Item href={ROUTES.MAIL_COMPETITOR_JOVES}>Jove 48</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
      </Navbar.Collapse>
      <Navbar.Collapse className="justify-content-end">
          <Form inline='true' className='mx-2'>
            <SignOutButton/>
          </Form>
      </Navbar.Collapse>
    </Navbar>
    )}
  </div>
);

const NavigationNonAuth = () => (
  <ul style={{ display: 'none' }}>
    <li>
      <Link to={ROUTES.FORM_FILMS}>Llargmetratges</Link>
    </li>
  </ul>
);

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps)(Navigation);
