export const LANDING = '/';
export const APP_QR = '/app';
export const TV3_QR = '/tv3';
export const FORM_FILMS = '/llargs';
export const FORM_SHORTS = '/curts';
export const FORM_SCHOOLS = '/centres-educatius';
export const FORM_DOCS = '/documentals';
export const FORM_MUSIC = '/videoclips';
export const FORM_YOUNG = '/jove';
export const SIGN_UP = '/registre';
export const SIGN_IN = '/accedir';
export const HOME = '/home';
export const ITEM = '/film';
export const ITEM_EDIT = '/film-edit';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';

// DASHBOARD
export const DASHBOARD = '/llistat';
export const DASHBOARD_LLARGS = DASHBOARD + FORM_FILMS;
export const DASHBOARD_CURTS = DASHBOARD + FORM_SHORTS;
export const DASHBOARD_CENTRES = DASHBOARD + FORM_SCHOOLS;
export const DASHBOARD_DOCS = DASHBOARD + FORM_DOCS;
export const DASHBOARD_VIDEOCLIPS = DASHBOARD + FORM_MUSIC;
export const DASHBOARD_JOVES = DASHBOARD + FORM_YOUNG;

// REGULAR LIST
export const LIST = '/llistat';
export const LIST_LLARGS = LIST + FORM_FILMS;
export const LIST_CURTS = LIST + FORM_SHORTS;
export const LIST_CENTRES = LIST + FORM_SCHOOLS;
export const LIST_DOCS = LIST + FORM_DOCS;
export const LIST_VIDEOCLIPS = LIST + FORM_MUSIC;
export const LIST_JOVES = LIST + FORM_YOUNG;

// TOP LIST
export const TOP = '/top';
export const TOP_LLARGS = TOP + FORM_FILMS;
export const TOP_CURTS = TOP + FORM_SHORTS;
export const TOP_CENTRES = TOP + FORM_SCHOOLS;
export const TOP_DOCS = TOP + FORM_DOCS;
export const TOP_VIDEOCLIPS = TOP + FORM_MUSIC;
export const TOP_JOVES = TOP + FORM_YOUNG;

// SELECTED LIST
export const CHOICE = '/seleccio';
export const CHOICE_LLARGS = CHOICE + FORM_FILMS;
export const CHOICE_CURTS = CHOICE + FORM_SHORTS;
export const CHOICE_CENTRES = CHOICE + FORM_SCHOOLS;
export const CHOICE_DOCS = CHOICE + FORM_DOCS;
export const CHOICE_VIDEOCLIPS = CHOICE + FORM_MUSIC;
export const CHOICE_JOVES = CHOICE + FORM_YOUNG;

// SELECTED LIST
export const CHOICE_JUDGE = '/seleccio-jurat';
export const CHOICE_JUDGE_LLARGS = CHOICE_JUDGE + FORM_FILMS;
export const CHOICE_JUDGE_CURTS = CHOICE_JUDGE + FORM_SHORTS;
export const CHOICE_JUDGE_CENTRES = CHOICE_JUDGE + FORM_SCHOOLS;
export const CHOICE_JUDGE_DOCS = CHOICE_JUDGE + FORM_DOCS;
export const CHOICE_JUDGE_VIDEOCLIPS = CHOICE_JUDGE + FORM_MUSIC;
export const CHOICE_JUDGE_JOVES = CHOICE_JUDGE + FORM_YOUNG;

// SELECTED LIST
export const CHOICE_COMM = '/seleccio-comunicacio';
export const CHOICE_COMM_LLARGS = CHOICE_COMM + FORM_FILMS;
export const CHOICE_COMM_CURTS = CHOICE_COMM + FORM_SHORTS;
export const CHOICE_COMM_CENTRES = CHOICE_COMM + FORM_SCHOOLS;
export const CHOICE_COMM_DOCS = CHOICE_COMM + FORM_DOCS;
export const CHOICE_COMM_VIDEOCLIPS = CHOICE_COMM + FORM_MUSIC;
export const CHOICE_COMM_JOVES = CHOICE_COMM + FORM_YOUNG;


// MAIL GENERATOR LIST
export const MAIL_SELECTED = '/correus-seleccio-oficial';
export const MAIL_SELECTED_LLARGS = MAIL_SELECTED + FORM_FILMS;
export const MAIL_SELECTED_CURTS = MAIL_SELECTED + FORM_SHORTS;
export const MAIL_SELECTED_CENTRES = MAIL_SELECTED + FORM_SCHOOLS;
export const MAIL_SELECTED_DOCS = MAIL_SELECTED + FORM_DOCS;
export const MAIL_SELECTED_VIDEOCLIPS = MAIL_SELECTED + FORM_MUSIC;
export const MAIL_SELECTED_JOVES = MAIL_SELECTED + FORM_YOUNG;

// MAIL GENERATOR LIST
export const MAIL_COMPETITOR = '/correus-participants';
export const MAIL_COMPETITOR_LLARGS = MAIL_COMPETITOR + FORM_FILMS;
export const MAIL_COMPETITOR_CURTS = MAIL_COMPETITOR + FORM_SHORTS;
export const MAIL_COMPETITOR_CENTRES = MAIL_COMPETITOR + FORM_SCHOOLS;
export const MAIL_COMPETITOR_DOCS = MAIL_COMPETITOR + FORM_DOCS;
export const MAIL_COMPETITOR_VIDEOCLIPS = MAIL_COMPETITOR + FORM_MUSIC;
export const MAIL_COMPETITOR_JOVES = MAIL_COMPETITOR + FORM_YOUNG;
