import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import Form from 'react-bootstrap/Form';
import ModalImage from "react-modal-image";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FileUploader from '../FileUploader';
import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import { getImageSize } from 'react-image-size';
import { isEmpty } from 'underscore';

//import FilmList from '../Films';

let filmType = '';
let filmUid = '';

class HomePage extends Component {
  constructor(props) {
    super(props);
    filmType = props.match.params.type ? props.match.params.type : 'llargs';
    filmUid = props.match.params.uid ? props.match.params.uid : null;
    this.authUser = JSON.parse(localStorage.getItem('authUser'));
    this.state = {
      type: null,
      validated: null
    }

    switch(filmType) {
      case 'curts':
        this.state.type = 'CURTS';
      break;
      case 'documentals':
        this.state.type = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.type = 'CENTRES';
      break;
      case 'videoclips':
        this.state.type = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.type = 'JOVES';
      break;
      case 'llargs':
      default:
        this.state.type = 'LLARGS';
      break;
    }
    this.Edition = CONFIG.CURRENT_EDITION + '_' + this.state.type;
  }

  componentDidMount () {
    // empty
  }
  render () {
    return (
      <div>
          <p><a href={ROUTES['LIST_' + this.state.type]}> &larr; Tornar al llistat de { MESSAGES[this.state.type].charAt(0).toUpperCase() }{ MESSAGES[this.state.type].slice(1) }</a></p>
          <Films />
        
      </div>
    )
  }
}

class FilmsBase extends Component {
  constructor(props) {
    super(props);

    this.authUser = JSON.parse(localStorage.getItem('authUser'));

    this.state = {
      loading: true,
      film: null,
      API: null,
    };

    switch(filmType) {
      case 'curts':
        this.state.API = this.props.firebase.short(filmUid);
        this.filmTypeName = 'CURTS';
      break;
      case 'documentals':
        this.state.API = this.props.firebase.doc(filmUid);
        this.filmTypeName = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.API = this.props.firebase.school(filmUid);
        this.filmTypeName = 'CENTRES';
      break;
      case 'videoclips':
        this.state.API = this.props.firebase.music(filmUid);
        this.filmTypeName = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.API = this.props.firebase.young(filmUid);
        this.filmTypeName = 'JOVES';
      break;
      case 'llargs':
        default:
          this.state.API = this.props.firebase.film(filmUid);
          this.filmTypeName = 'LLARGS';
        break;
    }
  }
  async fetchImageSize(imageUrl, newField) {
      try {
          const a1 = new Image;
          a1.src=imageUrl;
          await getImageSize(imageUrl).then(
            result => this.setState({ [newField] : result }
          ));
      } catch (error) {
          console.error(error);
      }
  }
  
  componentDidMount() {
    this.setState({ loading: true });

    this.state.API.once('value', snapshot => {
      
      if (snapshot) {
        // convert messages list from snapshot
        this.setState({
          film: snapshot.val(),
          loading: false,
        });
        
        this.fetchImageSize(this.state.film.imageCover, 'imageCoverDimensions');
        this.fetchImageSize(this.state.film.imageFrame1, 'imageFrame1Dimensions');
        this.fetchImageSize(this.state.film.imageFrame2, 'imageFrame2Dimensions');
        this.fetchImageSize(this.state.film.imageFrame3, 'imageFrame3Dimensions');

        if (this.state.film && (this.state.film.filmLink || this.state.film.eventLinkYouTube)) {
          // - Supported YouTube URL formats:
          //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
          //   - http://youtu.be/My2FRPA3Gf8
          //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
          // - Supported Vimeo URL formats:
          //   - http://vimeo.com/25451551
          //   - http://player.vimeo.com/video/25451551
          // - Also supports relative URLs:
          //   - //player.vimeo.com/video/25451551
    
          let filmEmbed = this.state.film.eventLinkYouTube != undefined ? this.state.film.eventLinkYouTube : this.state.film.filmLink; 
      

          if ( filmEmbed.indexOf('drive.google.com') > -1) {
            filmEmbed.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|drive.google\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|file\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\S+)?\/([A-Za-z0-9._%-]*)(\S+)?/);
            this.setState( { film: {...this.state.film, filmLinkType:'drive' ,filmLinkId: RegExp.$8 } })
          } else if (filmEmbed.indexOf('youtu.be') > -1) {
            this.setState( { film: {...this.state.film, filmLinkType:'youtube' ,filmLinkId: filmEmbed.split('/')[3] } })  
          } else {
            filmEmbed.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\S+)?/);
            if (RegExp.$3.indexOf('vimeo') > -1) {
              this.setState( { film: {...this.state.film, filmLinkType:'vimeo' ,filmLinkId: RegExp.$6 } })
            } else if (RegExp.$3.indexOf('drive.google.com') > -1) {
              this.setState( { film: {...this.state.film, filmLinkType:'drive' ,filmLinkId: RegExp.$6 } })
            } else if (RegExp.$3.indexOf('youtube') > -1) {
              this.setState( { film: {...this.state.film, filmLinkType:'youtube' ,filmLinkId: RegExp.$6 } })
            }
          }
        }
      } else {
        this.setState({ film: null, loading: false });
      }

      this.setState({ 
        isSelectable: this.state.film.isSelectable,
        isSelected: this.state.film.isSelected
      });

    });
  }

  handleUploadSuccess = (url, imageField) => {

    let data = this.state.film;

    data[imageField] = url;

    this.setState({
      [imageField]: url
    });

    if (data.filmName && data[imageField]) {
      this.state.API.set(data);    
    }
    
  }

  componentWillUnmount() {
    if (this.state.API) {
      this.state.API.off();
    }
  }



  updateLink(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data.filmLink = e.target.value.toString();
      this.setState({ filmLink: e.target.value});

    }
    if (data.filmLink) {
      this.state.API.set(data);    
    }
  }

  updatePass(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data.filmPass = e.target.value.toString();
      this.setState({ filmPass: e.target.value});

    }
    if (data.filmPass) {
      this.state.API.set(data);    
    }
  }

  updateIsSelectable(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['isSelectable'] = JSON.parse(e.target.value);
      this.setState({ isSelectable: JSON.parse(e.target.value)});

    }
    this.state.API.set(data);    
  }

  updateIsOnline(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['isOnline'] = JSON.parse(e.target.value);
      this.setState({ isOnline: JSON.parse(e.target.value)});

    }
    if (data.filmName) {
      this.state.API.set(data);    
    }
  }


  updateIsOffline(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['isOffline'] = JSON.parse(e.target.value);
      this.setState({ isOffline: JSON.parse(e.target.value)});

    }
    if (data.filmName) {
      this.state.API.set(data);    
    }
  }

  updateLength(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['filmLengthFixed'] = e.target.value;
      // this.setState({ isSelectable: JSON.parse(e.target.value)});

    }
    if (data.filmName && data.filmLengthFixed) {
      this.state.API.set(data);    
    }
  }

  updateEventDate(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['eventDate'] = e.target.value;
    }
    if (data.filmName && data.eventDate) {
      this.state.API.set(data);    
    }
  }


  updateShortGroup(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['shortGroup'] = e.target.value;
    }
    if (data.filmName && data.shortGroup) {
      this.state.API.set(data);    
    }
  }
  
  updateHideSelected(e) {

    let data = this.state.film;

    if ( e.target ) {
      data['hideSelected'] = e.target.checked ? true : false;
    }
    this.state.API.set(data); 
  }
  
  updateEventTime(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['eventTime'] = e.target.value;
    }
    if (data.filmName && data.eventTime) {
      this.state.API.set(data);    
    }
  }

  updateEventLinkTv3(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['eventLinkTv3'] = e.target.value;
    }
    if (data.filmName && data.eventLinkTv3) {
      this.state.API.set(data);    
    }
  }

  updateEventLinkYouTube(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['eventLinkYouTube'] = e.target.value;
    }
    if (data.filmName && data.eventLinkYouTube) {
      this.state.API.set(data);    
    }
  }

  updateEventLinkFilmin(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['eventLinkFilmin'] = e.target.value;
    }
    if (data.filmName && data.eventLinkTv3) {
      this.state.API.set(data);    
    }
  }

  updateAppTechnicalCast(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['appTechnicalCast'] = e.target.value;
      // this.setState({ isSelectable: JSON.parse(e.target.value)});

    }
    if (data.filmName && data.appTechnicalCast) {
      this.state.API.set(data);    
    }
  }

  updateAppShortDescription(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['appShortDescription'] = e.target.value;
      // this.setState({ isSelectable: JSON.parse(e.target.value)});

    }
    if (data.filmName && data.appShortDescription) {
      this.state.API.set(data);    
    }
  }



  
  updateIsSelected(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['isSelected'] = JSON.parse(e.target.value);
      this.setState({ isSelected: JSON.parse(e.target.value)});

    }
    if (data.filmName) {
      this.state.API.set(data);    
    }
    // this.state.API.set(data);
  }
  

  sendForm(e) {
   
    e.preventDefault();

    const form = e.currentTarget;

    this.setState({ validated: true });

    // GET ORIGINAL DATA
    let data = this.state.film;

    if (form.checkValidity() === false) {
      document.documentElement.scrollTop = 0;
      e.stopPropagation();
    } else {

        // OVERWRITE THE VALUES WITH THE ONES IN THE FORM
        data.filmName = form.elements['filmName'].value;
        data.filmDirector = form.elements['filmDirector'].value;
        data.producerCompany = form.elements['producerCompany'].value;

        data.filmLength = form.elements['filmLength'].value;
        data.filmYear = form.elements['filmYear'].value;
        data.filmCity = form.elements['filmCity'].value;
        data.filmState = form.elements['filmState'].value;

        data.filmActoring = form.elements['filmActoring'].value;
        data.filmSynopsis = form.elements['filmSynopsis'].value;

        data.filmLink = form.elements['filmLink'].value;
        data.filmPass = form.elements['filmPass'].value;

        
        // STORE DATA
        this.state.API.set(data);
        console.log(data);
        this.setState({ success: true });
    }
  };

  render() {
    const { film } = this.state;
    return (
      <div>
        { (film && (this.authUser.roles['EDITOR'] || this.authUser.roles['ADMIN'] )) ? (
          <div className="row">
              <div className='col-sm-8'>
                  <Form noValidate validated={ this.state.validated } id="formFilm" onSubmit={this.sendForm.bind(this)}>
                        <Row>
                          <Form.Group controlId="filmName" className='mb-3'>
                            <Form.Label>Títol de la producció</Form.Label>
                            <Form.Control
                              required
                              type='text'
                              defaultValue={film.filmName ? film.filmName : '' }
                              placeholder='Ex: La gran pel·lícula'
                            />
                            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group controlId="filmDirector" className='mb-3'>
                            <Form.Label>Director/a</Form.Label>
                            <Form.Control
                              required
                              type='text'
                              placeholder='Ex: Joana Roig'
                              defaultValue={film.filmDirector ? film.filmDirector : '' }
                            />
                            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group controlId="producerCompany" className='mb-3'>
                            <Form.Label>Empreses productores (separades per coma)</Form.Label>
                            <Form.Control
                              required
                              defaultValue={film.producerCompany ? film.producerCompany : '' }
                              type='text'
                              placeholder='Ex: Produccions exemplars, ABC, DEF'
                            />
                            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row>
                          <Col>
                          <Form.Group  className="mb-3" controlId="filmYear">
                            <Form.Label>Any de producció</Form.Label>
                            <Form.Control
                              required
                              type='number'
                              placeholder={CONFIG.CURRENT_EDITION}
                              defaultValue={film.filmYear ? film.filmYear : '' }
                            />
                            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                          </Form.Group>
                          </Col>
                          <Col>
                          <Form.Group controlId="filmLength" className='mb-3'>
                            <Form.Label>Durada (min.)</Form.Label>
                            <Form.Control
                              required
                              defaultValue={film.filmLength ? film.filmLength : '' }
                              type='number'
                              placeholder='Ex: 120'
                              step="1"
                            />
                            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                          </Form.Group>
                          </Col>
                          </Row>
                          <Row>
                            <Col sm>
                          <Form.Group  className="mb-3" controlId="filmCity">
                            <Form.Label>Localitat</Form.Label>
                            <Form.Control
                              required
                              type='text'
                              placeholder='Ex: Roda de Berà'
                              defaultValue={film.filmCity ? film.filmCity : '' }
                            />
                            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                          </Form.Group>
                          </Col>
                          <Col sm>
                          <Form.Group  className="mb-3" controlId="filmState">
                            <Form.Label>Província</Form.Label>
                            <Form.Control as="select"
                              required
                              defaultValue={film.filmState ? film.filmState : '' }
                            >
                              <option value=''>Selecciona una opció...</option>
                              { CONFIG.SPAIN_STATES.map((t,k) => <option key={k} value={t}>{t}</option>) } 
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                          </Form.Group>
                          </Col>
                          </Row>
                          <Row>
                          <Form.Group  className="mb-3" controlId="filmActoring">
                            <Form.Label>Intèrprets principals (fes servir comes per separar els noms)</Form.Label>
                            <Form.Control
                              as='textarea'
                              required
                              rows="3"
                              placeholder='Ex: Júlia Roig, Pere Verd....'
                              defaultValue={film.filmActoring ? film.filmActoring : '' }
                            >
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group  className="mb-3" controlId="filmSynopsis">
                            <Form.Label>Sinopsi (inferior a 350 caràcters)</Form.Label>
                            <Form.Control
                              as='textarea'
                              required
                              rows='4'
                              maxLength='350'
                              placeholder='Ex: Un noi coneix a una noia i....'
                              defaultValue={film.filmSynopsis ? film.filmSynopsis : '' }
                            >
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                          </Form.Group>
                          </Row>
                          <Row>
                            <Col sm>
                          <Form.Group  className="mb-3" controlId="filmLink">
                            <Form.Label>Enllaç (Vimeo, Youtube o Google Drive)</Form.Label>
                            <Form.Control
                              required
                              type='text'
                              placeholder='Ex: http://vimeo.com/exemple'
                              defaultValue={film.filmLink ? film.filmLink : '' }
                            >
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group  className="mb-3" controlId="filmPass">
                            <Form.Label>Contrassenya</Form.Label>
                            <Form.Control
                              required
                              type='text'
                              placeholder='Ex: 1234'
                              defaultValue={film.filmPass ? film.filmPass : '' }
                            >
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                          </Form.Group>
                          <Button type='submit' className='btn btn-lg btn-success'><strong>Actualitzar dades</strong></Button>

                          </Col>
                          <Col sm>
                              { film.filmLinkType === 'vimeo' &&
                                <div style={{ margin: '20px 0 10px', width: '100%', border:'1px solid #CCC', padding: '0 0 50% 0', background: '#000', display: 'block', position: 'relative'}}>
                                  <iframe title="vimeo"  src={"https://player.vimeo.com/video/" + film.filmLinkId } style={{  width: '100%', height: '100%', position: 'absolute'}} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                </div>
                              }
                              { film.filmLinkType === 'youtube' &&
                                <div style={{ margin: '20px 0 10px', width: '100%',border:'1px solid #CCC', padding: '0 0 50% 0', background: '#000', display: 'block', position: 'relative'}}>
                                  <iframe title="youtube" style={{  width: '100%', height: '100%', position: 'absolute'}}  src={"https://www.youtube.com/embed/" + film.filmLinkId } frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                { film.eventLinkYouTube && !film.imageCover && ( 
                                  <div>
                                    <img src={"http://i3.ytimg.com/vi/" + film.filmLinkId + "/maxresdefault.jpg"} style={{ maxWidth: '100%' }} />
                                    <img src={"http://i3.ytimg.com/vi/" + film.filmLinkId + "/hqdefault.jpg"} style={{ maxWidth: '100%' }} />
                                    </div>
                                  )}
                                </div>
                              }
                              { film.filmLinkType === 'drive' &&
                              <div style={{ margin: '20px 0 10px', width: '100%', border:'1px solid #CCC', padding: '0 0 50% 0', background: '#000', display: 'block', position: 'relative'}}>
                                <iframe title="drive" src={film.filmLink.replace('view?','preview?')} frameBorder="0" style={{  width: '100%', height: '100%', position: 'absolute'}} ></iframe>
                              </div>
                              }
                          </Col>
                          </Row>
                        </Form>
                    
                    
              </div>
              <div className='col-sm-4 bg-light'>
                <small>
                {this.state.imageCoverDimensions && this.state.imageCoverDimensions.width > 1200 && this.state.imageCoverDimensions.height > 1200 ? '\u2705' : '\u274C'} Píxels:&nbsp;
                {this.state.imageCoverDimensions ? this.state.imageCoverDimensions.width : ''}
                x
                {this.state.imageCoverDimensions ? this.state.imageCoverDimensions.height : ''} 
                </small>
                  <FileUploader
                    hidden
                    accept="image/*"
                    imageField='imageCover'
                    imageUrl={film.imageCover}
                    onUploadSuccess={this.handleUploadSuccess}
                  />
                  <div style={{ paddingBottom: '10px' }} >
                    <small>
                    {this.state.imageFrame1Dimensions && this.state.imageFrame1Dimensions.width > 900 && this.state.imageFrame1Dimensions.height > 900 ? '\u2705' : '\u274C'}  Píxels:&nbsp;
                    {this.state.imageFrame1Dimensions ? this.state.imageFrame1Dimensions.width : ''}
                    x
                    {this.state.imageFrame1Dimensions ? this.state.imageFrame1Dimensions.height : ''} 
                    </small>
                      <FileUploader
                        hidden
                        accept="image/*"
                        imageField='imageFrame1'
                        imageUrl={film.imageFrame1}
                        onUploadSuccess={this.handleUploadSuccess}
                      />
                  </div>
                  <div style={{ paddingBottom: '10px' }}>
                  <small>
                  {this.state.imageFrame2Dimensions && this.state.imageFrame2Dimensions.width > 900 && this.state.imageFrame2Dimensions.height > 900 ? '\u2705' : '\u274C'} Píxels:&nbsp;
                    {this.state.imageFrame2Dimensions ? this.state.imageFrame2Dimensions.width : ''}
                    x
                    {this.state.imageFrame2Dimensions ? this.state.imageFrame2Dimensions.height : ''} 
                    </small>
                      <FileUploader
                        hidden
                        accept="image/*"
                        imageField='imageFrame2'
                        imageUrl={film.imageFrame2}
                        onUploadSuccess={this.handleUploadSuccess}
                      />
                  </div>
                  <div style={{ paddingBottom: '10px' }}>
                  <small>
                  {this.state.imageFrame3Dimensions && this.state.imageFrame3Dimensions.width > 900 && this.state.imageFrame3Dimensions.height > 900 ? '\u2705' : '\u274C'} Píxels:&nbsp;
                    {this.state.imageFrame3Dimensions ? this.state.imageFrame3Dimensions.width : ''}
                    x
                    {this.state.imageFrame3Dimensions ? this.state.imageFrame3Dimensions.height : ''} 
                    </small>
                      <FileUploader
                        hidden
                        accept="image/*"
                        imageField='imageFrame3'
                        imageUrl={film.imageFrame3}
                        onUploadSuccess={this.handleUploadSuccess}
                      />
                  </div>
                <div className='row' style={{ padding: '10px 0 0' }}>
                  { film.isSelectable && (
                  <Form style={{ display: 'block', overflow: 'hidden', paddingBottom: '10px' }} id="isSelectedForm" ref='isSelectedForm' >  
                    <strong className={this.state.isSelected === undefined ? '' : (this.state.isSelected === false ? 'text-danger' : 'text-success') }>Selecció Oficial? </strong>&nbsp;&nbsp;
                    <Form.Check
                      inline
                      label="Sí"
                      value= { true }
                      name="isSelected" 
                      onChange={this.updateIsSelected.bind(this)}
                      type='radio'
                      defaultChecked={film.isSelected === true}
                      id='isSelectedTrue'
                    />
                    <Form.Check
                      inline
                      label="No"
                      value={ false }
                      name="isSelected" 
                      onChange={this.updateIsSelected.bind(this)}
                      type='radio'
                      defaultChecked={film.isSelected === false}
                      id='isSelectedFalse'
                    />
                  </Form>
                  )}
                </div>
                <hr style={{ margin: '0 0 0' }} />
              
                  { film.isSelected && this.authUser.roles['EDITOR'] && true == false && (
                    <div> 
                      <h3><strong>INFO PER LA APP:</strong></h3>
                    <Form style={{ padding: '10px 0 0 10px', display: 'block', overflow: 'hidden' }} id="appShortDescriptionForm" ref='appShortDescriptionForm'>
                      Descripció curta:
                      <div style={{ padding: '5px 0px 0' }} className='form-group '>
                        <label htmlFor='appTechnicalCast' style={{ width: '100%' }}>
                            <textarea rows="3" name="appShortDescription" onBlur={this.updateAppShortDescription.bind(this)} defaultValue={film.appShortDescription  ? film.appShortDescription : '' } style={{ width: '100%', display: 'block' }} className='form-control'></textarea>
                          </label>
                      </div>
                    </Form>
                    <Form style={{ padding: '10px 0 0 10px', display: 'block', overflow: 'hidden' }} id="appTechnicalCastForm" ref='appTechnicalCastForm'>
                      Fitxa tecnica:
                      <div style={{ padding: '5px 0px 0' }} className='form-group '>
                        <label htmlFor='appTechnicalCast' style={{ width: '100%' }}>
                            <textarea rows="25" name="appTechnicalCast" onBlur={this.updateAppTechnicalCast.bind(this)} defaultValue={film.appTechnicalCast  ? film.appTechnicalCast : '' } style={{ width: '100%', display: 'block' }} className='form-control'></textarea>
                          </label>
                      </div>
                    </Form>
                    </div>
                  )}  
                { this.authUser.roles['ADMIN'] && (
                  <div>
                      
                  { film.isSelected && this.authUser.roles['EDITOR'] && true == false && (
                  <div>
                    <Form style={{ padding: '0px 0 0 10px', display: 'none', overflow: 'hidden' }} id="eventDateForm" ref='eventDateForm'>
                        Data de Projecció / Destacat online:
                        <div style={{ padding: '5px 0px 0' }} className='form-group '>
                          <label htmlFor='eventDate' style={{ padding: '0 10px 0', display: 'inline-block' }}><input name="eventDate" onBlur={this.updateEventDate.bind(this)} type='date' defaultValue={film.eventDate ? film.eventDate : '' } style={{ width: '180px', display: 'inline-block' }} className='form-control' /></label>
                          <button onClick={ (e) => { e.preventDefault() } }>Guardar</button>
                        </div>
                      </Form>
                      <Form id="shortGroupForm" ref='shortGroupForm'>
                        <Form.Group controlId="shortGroup">
                          <Form.Label>Grup</Form.Label>
                          <Form.Control as="select"
                            onBlur={this.updateShortGroup.bind(this)}
                            defaultValue={film.shortGroup ? film.shortGroup: ''}
                            ref={shortGroup => this.inputShortGroup = shortGroup}
                          >
                            <option value=''>Selecciona una opció...</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                            <option value={0}>Nit Jove</option>
                            </Form.Control>
                          <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_UNSELECTED }</Form.Control.Feedback>
                        </Form.Group>
                      </Form>
                      <Form>
                        <label htmlFor='hideSelected' style={{display:'none'}}><input type="checkbox" id="hideSelected" name="hideSelected" onChange={this.updateHideSelected.bind(this)} defaultChecked={film.hideSelected} /> <small>Ocultar a la Selecció Oficial</small></label>
                      </Form>
                    <hr />
                    <div  >
                      <Form id="isOfflineForm" ref='isOfflineForm' style={{ padding: '10px 0 0 0', display: 'none', overflow: 'hidden' }}>
                        <strong>Projecció física?</strong>
                        <div style={{ padding: '5px 0px 0' }} className='form-group '>
                          <label htmlFor='isOffline' style={{ padding: '0 20px 0' }}><input name="isOffline" defaultChecked={film.isOffline === true} onChange={this.updateIsOffline.bind(this)} type='radio' value="true" className='form-control' style={{ width: '16px', height: '16px', display: 'inline-block' }} /> Si</label>
                          <label htmlFor='isOffline'><input name="isOffline" defaultChecked={film.isOffline === false} onChange={this.updateIsOffline.bind(this)} type='radio' value="false" className='form-control' style={{ width: '16px', height: '16px', display: 'inline-block' }} /> No</label>
                        </div>
                      </Form>
                      { film.isOffline && this.authUser.roles['EDITOR'] && (
                        <Form style={{ padding: '10px 0 0 10px', display: 'block', overflow: 'hidden' }} id="eventTimeForm" ref='eventTimeForm'>
                          Hora de Projecció:
                          <div style={{ padding: '5px 0px 0' }} className='form-group '>
                            <label htmlFor='eventTime' style={{ padding: '0 10px 0', display: 'inline-block' }}><input name="eventDate" onBlur={this.updateEventTime.bind(this)} type='time' defaultValue={film.eventTime ? film.eventTime : '' } style={{ width: '180px', display: 'inline-block' }} className='form-control' /></label>
                            <button onClick={ (e) => { e.preventDefault() } }>Guardar</button>
                          </div>
                        </Form>
                      )}
                      <hr />
                      <Form id="isOnlineForm" ref='isOnlineForm' >
                        <strong>Online?</strong>
                        <div style={{ padding: '5px 0px 0' }} className='form-group '>
                          <label htmlFor='isOnline' style={{ padding: '0 20px 0' }}><input name="isOnline" defaultChecked={film.isOnline === true} onChange={this.updateIsOnline.bind(this)} type='radio' value="true" className='form-control' style={{ width: '16px', height: '16px', display: 'inline-block' }} /> Si</label>
                          <label htmlFor='isOnline'><input name="isOnline" defaultChecked={film.isOnline === false} onChange={this.updateIsOnline.bind(this)} type='radio' value="false" className='form-control' style={{ width: '16px', height: '16px', display: 'inline-block' }} /> No</label>
                        </div>
                      </Form>
                      { film.isOnline && this.authUser.roles['EDITOR'] && (
                        <div>
                            <Form style={{ padding: '0', display: 'block', overflow: 'hidden' }} id="eventLinkYouTubeForm" ref='eventLinkYouTubeForm'>
                              Enllaç YouTube:
                              <label htmlFor='eventLinkYouTube' style={{ padding: '0 0 20px', display: 'block', width: '100%' }}><input name="eventLinkYouTube" onBlur={this.updateEventLinkYouTube.bind(this)} type='url' defaultValue={film.eventLinkYouTube ? film.eventLinkYouTube : '' } style={{ width: '100%'}} className='form-control' /></label>
                            </Form>
                              <Form style={{ padding: '0', display: 'block', overflow: 'hidden' }} id="eventLinkTv3Form" ref='eventLinkTv3Form'>
                              Enllaç TV3 a la carta:
                              <label htmlFor='eventLinkTv3' style={{ padding: '0 0 20px', display: 'block', width: '100%' }}><input name="eventLinkTv3" onBlur={this.updateEventLinkTv3.bind(this)} type='url' defaultValue={film.eventLinkTv3 ? film.eventLinkTv3 : '' } style={{ width: '100%'}} className='form-control' /></label>
                            </Form>
                            <Form style={{ padding: '0', display: 'block', overflow: 'hidden' }} id="eventLinkFilminForm" ref='eventLinkFilminForm'>
                              Enllaç Filmin:
                              <label htmlFor='eventLinkFilmin' style={{ padding: '0 0 20px', display: 'block', width: '100%' }}><input name="eventLinkFilmin" onBlur={this.updateEventLinkFilmin.bind(this)} type='url' defaultValue={film.eventLinkFilmin ? film.eventLinkFilmin : '' } style={{ width: '100%'}} className='form-control' /></label>
                            </Form>
                        </div>
                      )}   
                    </div>
                  </div>
                  )}
                </div>
              )}
                
              </div>
          </div>
        ) : (
          <React.Fragment>
              <img alt="Carregant..." src="/img/loading.gif" style={{ margin: '0 auto', maxWidth: '100%'}} />
          </React.Fragment>
        )}
      </div>
    );
  }
}
const Films = withFirebase(FilmsBase);

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(HomePage);