import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import _ from 'lodash';

import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { Link } from 'react-router-dom';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import { Rating } from 'react-simple-star-rating'

let filmType = '';
let authUser = JSON.parse(localStorage.getItem('authUser'));


class FilmListPage extends Component {
  constructor(props) {
    super(props);
    filmType = props.match.params.type ? props.match.params.type : 'llargs';
    this.state = {
      type: null,
    }

    switch(filmType) {
      case 'curts':
        this.state.type = 'CURTS';
      break;
      case 'documentals':
        this.state.type = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.type = 'CENTRES';
      break;
      case 'videoclips':
        this.state.type = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.type = 'JOVES';
      break;
      case 'llargs':
        default:
          this.state.type = 'LLARGS';
        break;
    }
  }
  render () {
    return (
      <div style={{ padding: '20px 0 0'}}>
        { this.state.type === 'CENTRES' && (
          <>
            <span>Curs: </span>
            { window.location.pathname === ROUTES.LIST_CENTRES ? (
              <strong>&#x27A4; Tots</strong>
            ) : (
              <Link to={ ROUTES.LIST_CENTRES }>Tots</Link>
            )}
            &nbsp;|&nbsp;
            { window.location.pathname === ROUTES.LIST_CENTRES + '/primaria' ? (
              <strong>&#x27A4; Primaria</strong>
            ) : (
              <Link to={ ROUTES.LIST_CENTRES + '/primaria' }>Primaria</Link>
            )}
            &nbsp;|&nbsp;
            { window.location.pathname === ROUTES.LIST_CENTRES + '/secundaria' ? (
              <strong>&#x27A4; Secundaria</strong>
            ) : (
              <Link to={ ROUTES.LIST_CENTRES + '/secundaria' }>Secundaria</Link>
            )}
            &nbsp;
          <br/><br/>
          </>
        )}
        <Films />
      </div>
    )
  }
}

const FilmList = ({ films }) => (
  <Row className='film-list' xs={1} md={3} lg={4}>
    {films.map(film => (
      <FilmItemFirebase key={film.uid} film={film} />
    ))}
  </Row>
);

class FilmItem extends Component {
  constructor(props) {
    super(props);

    this.authUser = JSON.parse(localStorage.getItem('authUser'));

    this.state = {
      firebase: props.firebase,
      film: props.film,
    }

  }

  componentDidMount () {
    this.props.firebase.database().ref(CONFIG.USER_NOTES + this.authUser.uid + '/' + this.state.film.uid).once('value', snapshotUserFilm => {
      if (snapshotUserFilm.val()) {
        this.setState({
          userFilm: snapshotUserFilm.val()
        });
      } else {
        this.setState({
          userFilm: {
            userFilmNote: '',
            userFilmRank: ''
          }
        });
      }
    })
  }

  render () {
    const { film, userFilm } = this.state
    const currentYear = new Date().getFullYear()
    return (
      <>
              { (authUser.roles['ADMIN'] || (!authUser.roles['ADMIN'] && film.isSelectable)) && (
      <Col>
            <Card style={ userFilm && (userFilm.userFilmRank > 0) ? { background: 'rgba(40,167,69,.2)', margin: '0px 0px 20px 0px' } : { margin: '0px 0px 20px 0px' } }>
              <Card.Link href={ROUTES['ITEM'] + '/' + filmType + '/' + film.uid } style={{textDecoration: 'none'}}>
                <Card.Img variant="top" src={film.imageList} />
              <Card.Body style={{ padding: '5px 10px 20px 10px', textDecoration: 'none'}}>
                <div style={{ padding: '0px 0px 5px 0px'}}>
                  <Rating size='20' initialValue={ userFilm && (userFilm.userFilmRank > 0) ? userFilm.userFilmRank : 0 } readonly={true} />
                </div>
                <span style={{ fontSize: '1.5em', lineHeight: '0', color: '#dc3545',textDecorationColor:'#dc3545'  }}><strong>{ film.filmName }</strong></span><br />          
                <small className='text-muted'> de <strong>{film.filmDirector}</strong> </small><br/>
                <small className='text-muted'>
                  <strong>{film.filmYear} · {film.filmLengthFixed ? film.filmLengthFixed : film.filmLength} min · {film.filmGenre ? film.filmGenre : ''} · {film.filmCity}</strong>, {film.filmState} ({film.filmCountry.substring(0,3).toUpperCase()})
                  {film.filmSignDate && (
                    <i>
                      <br />
                      Data d'inscripció: {film.filmSignDate}
                    </i>
                  )}

                </small>
                <div style={{ padding: '10px 0px 0px 0px'}}>
                <span className={"badge " + (film.acceptOnline === 'Sí' ? 'badge-success' : 'badge-secondary')}>ONLINE {film.acceptOnline ? film.acceptOnline.toUpperCase() : ''}</span>&nbsp; 
                <span className={"badge " + (film.filmPremiere === 'Estrena' ? 'badge-success' : 'badge-secondary')}>{film.filmPremiere ? film.filmPremiere.toUpperCase(): ''}</span>&nbsp;
                { ((film.filmTarragona !== undefined && film.filmTarragona === true) || ( film.filmTarragona === undefined  && film.filmState && film.filmState.toUpperCase().indexOf('TARRAGONA') !== -1 )) && (
                <span className="badge badge-warning">CANDITAT CT:{film.filmState}</span>
                )}
                { film.filmTarragona === 2 && (
                <span className="badge badge-warning">CT: {film.filmTarragonaStaff === true? 'Equip, ':''} {film.filmTarragonaLocation === true? 'Localització (' + film.filmState +')' :''}</span>
                )}
                </div>
              </Card.Body>
              </Card.Link>

            { authUser.roles['ADMIN'] && (
              <>
                <a href={ROUTES['ITEM_EDIT'] + '/' + filmType + '/' + film.uid } className={'btn btn-secondary btn-sm ' + (film.isSelectable === undefined ? 'bg-warning' : (film.isSelectable === false ? 'bg-danger' : 'bg-success')) }>Administrar</a>
              </>
            )}
            </Card>
          </Col>
        )}
      </>
    )
  }
}

const FilmItemFirebase = withFirebase(FilmItem);

class FilmsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      films: [],
      API: null,
    };

    switch(filmType) {
      case 'curts':
        this.state.API = this.props.firebase.shorts();
        this.filmTypeName = 'CURTS';
      break;
      case 'documentals':
        this.state.API = this.props.firebase.docs();
        this.filmTypeName = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.API = this.props.firebase.schools();
        this.filmTypeName = 'CENTRES';
      break;
      case 'videoclips':
        this.state.API = this.props.firebase.musics();
        this.filmTypeName = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.API = this.props.firebase.youngs();
        this.filmTypeName = 'JOVE';
      break;
      case 'llargs':
        default:
          this.state.API = this.props.firebase.films();
          this.filmTypeName = 'LLARGS';
        break;
    }
  }
  getFilmsByIsSelectable( films, value ) {
    let filmsSelectable = [];
    if (films !== null) {
      for ( var k = 0; k < films.length; k++) {
        if (films[k].isSelectable === value) {
          filmsSelectable.push(films[k])
        }
      }
    }
    return filmsSelectable;
  }

  // https://firebasestorage.googleapis.com/v0/b/films-cat.appspot.com/o/2025_images%2Fthumbs%2F4673d966-002c-44d0-9973-5b4d78903557_800x800.jpeg?alt=media&token=9427f754-61ae-4fc3-8835-08f611e713b8
  // https://firebasestorage.googleapis.com/v0/b/films-cat.appspot.com/o/2025_images%2Fthumbs%2F4673d966-002c-44d0-9973-5b4d78903557_800x800.jpeg?token=9427f754-61ae-4fc3-8835-08f611e713b8
  

  resizedImage( imageUrl ) {


    const festivalEdition = localStorage.getItem('currentEdition') !== null ? localStorage.getItem('currentEdition') : CONFIG.CURRENT_EDITION;
    if (imageUrl) {
      let path = imageUrl.split( festivalEdition + '_images%2F')[0] + festivalEdition + '_images%2Fthumbs%2F';
      let imageURlResponse = imageUrl.split( festivalEdition + '_images%2F')[1];
      let token = imageUrl.split( 'token')[1];

      if (imageURlResponse !== undefined) {
       return path + imageURlResponse.split('.')[0] + '_800x800.jpeg' + '?alt=media&token' + token;
      }  else {
      return false
    }

    } else {
      return false
    }
  }

  UpdateFilmList() {
    this.setState({ loading: true });
    this.state.API.on('value', snapshot => {
      const filmObject = snapshot.val();
      if (filmObject) {
        const filmList = Object.keys(filmObject).map(key => ({
          ...filmObject[key],
          uid: key,
          imageList: this.resizedImage(filmObject[key].imageFrame1)
        }));
        // convert messages list from snapshot

        let filmListFiltered = {};
        switch(window.location.pathname.split('/')[3]) {
          case 'secundaria':
            filmListFiltered = _.filter( filmList, function(film) { return (film.filmSchoolLevel === 'secundaria' || film.filmSchoolLevel === 'batxillerat' ) } );
          break;
          case 'primaria':
            filmListFiltered = _.filter( filmList, function(film) { return (film.filmSchoolLevel === 'primaria' || film.filmSchoolLevel === 'infantil' ) } );
          break;
          default:
            filmListFiltered = filmList;
          break;
        }

        this.setState({
          films: filmListFiltered.reverse(),
          loading: false,
        });
      } else {
        this.setState({ films: null, loading: false });
      }
    });
  }
  componentDidMount() {
    this.UpdateFilmList();
  }

  UNSAFE_componentWillReceiveProps() {
    this.UpdateFilmList();
  }
 
  componentWillUnmount() {
    if (this.state.API) {
      this.state.API.off();
    }
  }
  render() {
    const { films, loading, type } = this.state;
    return (
      <div>
        {films ? (
          <React.Fragment>
            {loading &&
              <React.Fragment>
                  <img alt="Carregant..." src="/img/loading.gif" style={{ margin: '0 auto', maxWidth: '100%'}} />
              </React.Fragment>
            }
            {!loading &&
              <Row>
                    <Col sm>
                      <h1>{ this.filmTypeName ? this.filmTypeName.charAt(0).toUpperCase() : ''}{ MESSAGES[this.filmTypeName] ? MESSAGES[this.filmTypeName].slice(1) : '' }</h1>
                      <p>{ MESSAGES[this.filmTypeName + '_FORM_DESC'] }</p>
                    </Col>
                    <Col sm>
                    <div className='card bg-light pt-1 mb-3 text-center'>
                      <Row>
                        <Col>
                          <dl className='mb-0'>
                            <dt><small className='text-muted'>Acceptats:</small></dt>
                            <dd className="h1 text-success">{this.getFilmsByIsSelectable(films, true).length}</dd>
                          </dl>
                        </Col>
                        <Col>
                          <dl className='mb-0'>
                            <dt><small className='text-muted'>Pendents:</small></dt>
                            <dd className="h1 text-warning">{this.getFilmsByIsSelectable(films, undefined).length}</dd>
                          </dl>
                        </Col>
                        <Col>
                          <dl className='mb-0'>
                            <dt><small className='text-muted'>Rebutjats:</small></dt>
                            <dd className="h1 text-danger">{this.getFilmsByIsSelectable(films, false).length}</dd>
                          </dl>
                        </Col>
                        <Col>
                          <dl className='mb-0'>
                            <dt><small className='text-muted'>Inscrits:</small></dt>
                            <dd className="h1 text-secondary">{films ? films.length : '0'}</dd>
                          </dl>
                        </Col>
                        </Row>
                      </div>      
                    </Col>
                  </Row>
            }
            {!loading && 
              <Row>
                <FilmList films={films} />
              </Row>
            }
          </React.Fragment>
        ) : (
          <Alert variant="warning">No hi ha produccions inscrites en aquesta categoría.</Alert>
        )}
      </div>
    );
  }
}
const Films = withFirebase(FilmsBase);

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(FilmListPage);